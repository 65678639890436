import dynamic from "next/dynamic";
const Chat = dynamic(() => import("@components/chat"));
interface ChatProps {
  chatSocket: unknown;
  isMod: boolean;
  chatOpen: boolean;
}
export default function ChatContainer({
  chatSocket,
  isMod,
  chatOpen
}: ChatProps) {
  if (!chatOpen) return null;
  return <Chat isMod={isMod} chatSocket={chatSocket} data-sentry-element="Chat" data-sentry-component="ChatContainer" data-sentry-source-file="chat-container.tsx" />;
}